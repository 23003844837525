export const FirebaseConfig = {
	"projectId": "ut-limo-c2331",
	"appId": "1:729658226783:web:1f126f0c4261c9a95383ea",
	"databaseURL": "https://ut-limo-c2331-default-rtdb.firebaseio.com",
	"storageBucket": "ut-limo-c2331.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCxBEyJtdPCPAmyjRJDZoU1ECMfVpw0IpE",
	"authDomain": "ut-limo-c2331.firebaseapp.com",
	"messagingSenderId": "729658226783",
	"measurementId": "G-R93GQLK8DV"
};